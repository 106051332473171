var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get, keys as _keys, pickBy as _pickBy } from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Item, Menu, Separator, useContextMenu } from 'react-contexify';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../../../../api/endpoints/endpoints';
import { SYSTEM_TYPE_REMASTERED } from '../../../../../../constants';
import { setRunningSpeedModels } from '../../../../../../remastered/features/installationPoint';
import { SystemTypeContext } from '../../../../../context/SystemTypeContext';
import { isTwfChart, onBlockedFftTwfMouseMove, setActiveChartOnClick } from '../../../../../helper/chart';
import { ChartFeaturesStore } from '../../../../../store/charts/chartsContent/ChartFeaturesStore';
import { FftChartsStore } from '../../../../../store/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '../../../../../store/charts/chartsContent/InstallationPointStore';
import { FrequencyConverter } from '../../features/fftTwf/frequencyConverter';
export var FftContextMenu = function (_a) {
    var chartIdentifier = _a.chartIdentifier, chartRef = _a.chartRef, children = _a.children, dispatch = _a.dispatch;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = useState(), point = _b[0], setPoint = _b[1];
    var _c = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        setActiveInstallationPoint: state.setActiveInstallationPoint,
        currentSpeed: state.currentSpeed,
    }); }, shallow), installationPoint = _c.installationPoint, setActiveInstallationPoint = _c.setActiveInstallationPoint, currentSpeed = _c.currentSpeed;
    var setStatesChartFeaturesStore = ChartFeaturesStore(function (state) { return state.setStates; });
    var _d = FftChartsStore(function (state) { return ({
        setStatesFftChartsStore: state.setStatesFftChartsStore,
        data: state.data,
    }); }, shallow), setStatesFftChartsStore = _d.setStatesFftChartsStore, data = _d.data;
    var show = useContextMenu({
        id: 'fft-context-menu',
    }).show;
    var onShow = useCallback(function (ev, chartIdentifier) {
        chartRef.current.chart.userOptions.isLockedCursor = false;
        onBlockedFftTwfMouseMove(false, chartRef.current.chart);
        setActiveChartOnClick(ev, chartIdentifier);
        var point = _get(chartRef, 'current.chart.hoverPoint', {}) || {};
        setPoint({
            x: _get(point, 'x'),
            y: _get(point, 'y'),
        });
        show({ event: ev });
    }, [chartRef]);
    var cpm = useMemo(function () {
        if (!(point === null || point === void 0 ? void 0 : point.x)) {
            return FrequencyConverter.fromHz(0, currentSpeed).toCpm();
        }
        var x = isTwfChart(chartIdentifier) ? 1 / (point === null || point === void 0 ? void 0 : point.x) : point === null || point === void 0 ? void 0 : point.x;
        return FrequencyConverter.fromHz(x, currentSpeed).toCpm();
    }, [point, chartIdentifier]);
    var onEquipmentSpeedSettings = useCallback(function () {
        setStatesChartFeaturesStore({
            equipmentSpeedVisible: true,
        });
    }, []);
    var onSetRunningSpeed = useCallback(function (isManually) {
        setStatesChartFeaturesStore({
            setRunningSpeed: {
                visible: true,
                cpm: isManually ? null : cpm.value,
                chartIdentifier: chartIdentifier,
            },
        });
    }, [point]);
    var onClearRunningSpeed = useCallback(function (type) {
        var data = { speed: null, speed_ratio: null, installation_point_id: installationPoint.id, type: type };
        Endpoints[selectedSystemType].changeSpeed(data).then(function (resp) {
            if (resp) {
                if (resp.success) {
                    // @ts-ignore
                    setRunningSpeedModels(data, setActiveInstallationPoint, installationPoint);
                    return toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                }
                return toast.error(resp.message, { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            }
        });
    }, [selectedSystemType, installationPoint]);
    var onSetFaultFrequency = useCallback(function () {
        setStatesChartFeaturesStore({
            setFaultFrequency: {
                visible: true,
                openedByChartIdentifier: chartIdentifier,
                installationPointId: installationPoint.id,
                faultFrequency: {
                    frequencyType: 'Hz',
                },
            },
        });
    }, [point]);
    var onMarkOutlier = useCallback(function () {
        var isOutlier = +_get(data, ['meta', chartIdentifier, 'is_outlier'], 0), readingId = _get(data, ['readingIds', chartIdentifier]), 
        // @ts-ignore
        chartIdentifiers = _keys(_pickBy(data.readingIds, function (readingIdItem) { return +readingIdItem === +readingId; })), updatedMeta = {};
        chartIdentifiers.forEach(function (chartIdentifier) {
            // @ts-ignore
            updatedMeta[chartIdentifier] = {
                readingFFT_id: readingId,
                is_outlier: Number(!isOutlier),
            };
        });
        Endpoints[selectedSystemType]
            .outlier({
            installationPointId: installationPoint.id,
            readingId: readingId,
            isOutlier: Number(!isOutlier),
        })
            .then(function (resp) {
            if (resp) {
                if (resp.success) {
                    setStatesFftChartsStore({
                        data: __assign(__assign({}, data), { meta: __assign(__assign({}, data.meta), updatedMeta) }),
                    });
                    return toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                }
                return toast.error(resp.message, { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            }
        });
    }, [selectedSystemType, data]);
    var onSetIdleThreshold = function () {
        setStatesChartFeaturesStore({ isVisibleIdleThresholdModal: true });
    };
    return (_jsxs(React.Fragment, { children: [_jsx("span", { onContextMenu: function (ev) { return onShow(ev, chartIdentifier); }, children: children }), _jsxs(Menu, { id: 'fft-context-menu', children: [selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsxs(_Fragment, { children: [_jsxs(Item, { onClick: function () { return onSetRunningSpeed(false); }, children: ["Set running speed at ", cpm.numberFormat(), " CPM"] }), _jsx(Item, { onClick: function () { return onSetRunningSpeed(true); }, children: "Set running speed manually" }), _jsx(Item, { onClick: function () { return onEquipmentSpeedSettings(); }, children: "Equipment speed settings" }), _jsx(Separator, {}), _jsx(Item, { onClick: function () { return onClearRunningSpeed('single'); }, children: "Clear running speed for this point" }), _jsx(Item, { onClick: function () { return onClearRunningSpeed('all'); }, children: "Clear running speed for equipment" }), _jsx(Separator, {})] })) : (_jsx(_Fragment, {})), _jsx(Item, { onClick: onSetFaultFrequency, children: "Set fault/forcing frequency" }), _jsx(Separator, {}), selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsx(Item, { onClick: onMarkOutlier, children: +_get(data, ['meta', chartIdentifier, 'is_outlier'], 0) === 0
                            ? 'Mark as outlier'
                            : 'Not outlier' })) : (_jsx(_Fragment, {})), selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsx(Item, { onClick: onSetIdleThreshold, children: "Set Idle Threshold" })) : (_jsx(_Fragment, {}))] })] }));
};
