import { jsx as _jsx } from "react/jsx-runtime";
import { has as _has } from 'lodash';
import React from 'react';
import { Button } from 'reactstrap';
import StatusLabelAmazon from '../../../../amazon/components/AIPreAi/StatusLabel';
import { SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import StatusLabelRemastered from '../../../../remastered/components/AIPreAi/StatusLabel';
import { UrgencyLabel } from '../../../../remastered/components/AIPreAi/UrgencyLabel';
import { DateTimeLabel } from '../../../../widgets/common';
import LinkWithPrefix from '../../../components/LinkWithPrefix';
import { translateByKey } from '../../../features/shared';
export var config = function (visibleState, selectedSystemType) {
    return {
        columns: [
            {
                name: 'title',
                visible: _has(visibleState, 'title') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.title : true,
                title: 'Title',
                sortable: false,
                component: function (row) {
                    return translateByKey(row, 'translates.en.title.text', row.title);
                },
            },
            {
                name: 'updated_at',
                visible: _has(visibleState, 'received') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.received : true,
                title: 'Received',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "needs_response_from_now_widget_".concat(row.id), dateTime: row.updated_at, fromNow: true }));
                },
            },
            {
                name: 'last_update',
                visible: _has(visibleState, 'last_update') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.last_update : true,
                title: 'Last Update',
                sortable: false,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "needs_response_widget_".concat(row.id), dateTime: row.updated_at, timeBreak: true }));
                },
            },
            {
                title: 'Urgency',
                name: 'urgency',
                visible: _has(visibleState, 'urgency') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.urgency : true,
                sortable: false,
                component: function (row) {
                    return _jsx(UrgencyLabel, { urgency: +row.urgency });
                },
            },
            {
                title: 'Status',
                name: 'status',
                visible: _has(visibleState, 'status') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.status : true,
                sortable: false,
                component: function (row) {
                    return selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsx(StatusLabelRemastered, { status: +row.state, decline: row.decline_reason, commentsCount: +row.comments_count, isTroubleshootingHold: row.is_troubleshooting_hold, isTroubleshootingWaiting: row.is_troubleshooting_waiting })) : (_jsx(StatusLabelAmazon, { status: +row.state, isTroubleshootingHoldWaiting: +row.is_troubleshooting_waiting, commentsCount: +row.comments_count }));
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row) {
                    return (_jsx(LinkWithPrefix, { to: "/action-item/".concat(row.id), target: "_blank", children: _jsx(Button, { color: "primary", size: "sm", children: "View" }) }));
                },
            },
        ],
        pagination: {
            disabled: true,
        },
        sort: [
            {
                field: 'updated_at',
                direction: 'desc',
            },
        ],
        disabledSearch: true,
        data: [],
        loader: false,
        hideTotalLabel: true,
    };
};
