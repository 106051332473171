var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { get as _get, orderBy as _orderBy, size as _size } from 'lodash';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { DEFAULT_READING_CONFIG, READING_TYPE_FFT_ACCELERATION_IMPACT_VUE_X, READING_TYPE_TWF_ACCELERATION_IMPACT_VUE_X, } from '../../../../constants';
import { getFftDates } from '../../../components/charts/chartsWrapper/features/fftTwf';
var params = new URLSearchParams(window.location.search);
var initialState = {
    loader: true,
    data: {},
    readings: DEFAULT_READING_CONFIG,
    readingFftIds: {},
    fftTimestampDates: {},
    fftAlerts: {},
    installationPointBearings: [],
    faultFrequencies: [],
    isChangedCalendar: false,
    fftLastSelectedDate: null,
    fftSelectedDate: params.get('fft_date') ? new Date(params.get('fft_date') || '') : null,
    fftSelectedDateChartIdentifier: null,
};
export var FftChartsStore = create()(devtools(function (set) { return (__assign(__assign({}, initialState), { fetchFftReadings: function (endpoint, setStatesFftImpactVueChartsStore) {
        set({ loader: true }, false, 'FftChartsStoreSetLoaderOnFetch');
        endpoint().then(function (resp) {
            var _a;
            if (resp) {
                set({
                    loader: false,
                    data: _size(resp.data)
                        ? {
                            auto_correlation: resp.data.auto_correlation,
                            equipment: resp.data.equipment,
                            fault_frequency: resp.data.fault_frequency,
                            fftTimestamps: resp.data.fftTimestamps,
                            installationPoint: resp.data.installationPoint,
                            meta: resp.data.meta,
                            overall: resp.data.overall,
                            pkpk: resp.data.pkpk,
                            readingIds: resp.data.readingIds,
                            sensor: resp.data.sensor,
                            rpm: resp.data.rpm,
                            rpmFrom: resp.data.rpmFrom,
                            rpmRatio: resp.data.rpmRatio,
                            rpmTimestamp: resp.data.rpmTimestamp,
                            isOverRange: resp.data.isOverRange,
                        }
                        : {},
                    readings: _get(resp, 'data.readings') ? _get(resp, 'data.readings') : DEFAULT_READING_CONFIG,
                    fftTimestampDates: _get(resp, 'data.readings')
                        ? getFftDates(_get(resp, 'data.fftTimestamps'), Object.keys(_get(resp, 'data.readings')))
                        : {},
                    installationPointBearings: _size(resp.installationPointBearings)
                        ? resp.installationPointBearings
                        : [],
                    faultFrequencies: _size(resp.faultFrequencies) ? resp.faultFrequencies : [],
                    fftAlerts: _size(resp.fftAlerts) ? resp.fftAlerts : {},
                }, false, 'FftChartsStoreSetDataOnFetch');
                var fftTimestampsHFDvueFlatten_1 = [];
                Object.values(((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.fftTimestampsHFDvue) || {}).forEach(function (fftTimestampsHFDvueDates) {
                    Object.values(fftTimestampsHFDvueDates || {}).forEach(function (items) {
                        Object.values(items || {}).forEach(function (item) {
                            fftTimestampsHFDvueFlatten_1.push(item);
                        });
                    });
                });
                fftTimestampsHFDvueFlatten_1 = _orderBy(fftTimestampsHFDvueFlatten_1, 'timestamp', 'desc');
                setStatesFftImpactVueChartsStore({
                    lastTimestamps: fftTimestampsHFDvueFlatten_1 && fftTimestampsHFDvueFlatten_1[0]
                        ? fftTimestampsHFDvueFlatten_1[0]
                        : null,
                    data: _size(resp.data)
                        ? {
                            equipment: resp.data.equipment,
                            fftTimestamps: resp.data.fftTimestampsHFDvue,
                            sensor: resp.data.sensor,
                            installationPoint: resp.data.installationPoint,
                        }
                        : {},
                    fftTimestampDates: _get(resp, 'data.readings')
                        ? getFftDates(_get(resp, 'data.fftTimestampsHFDvue'), [
                            READING_TYPE_FFT_ACCELERATION_IMPACT_VUE_X,
                            READING_TYPE_TWF_ACCELERATION_IMPACT_VUE_X,
                        ])
                        : {},
                });
            }
        });
    }, setStatesFftChartsStore: function (states) {
        set(states, false, 'FftChartsStoreSetStates');
    }, resetToDefault: function () {
        set(initialState);
    } })); }, { name: 'FftChartsStore' }));
