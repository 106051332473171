import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { RiSortAsc } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { useTitle } from '../../../../customHooks';
import Loader from '../../../../shared/components/Loader';
import ActivityLog from '../../../../shared/components/actionItem/activityLog';
import useDeactivationAnalystLog from '../../../../shared/hooks/AIPreAi/useDeactivationAnalystLog';
import { useDeleteSeverityIncrease } from '../../../../shared/hooks/actionItem/useDeleteSeverityIncrease';
import { useApproveSeverityIncreaseMutation } from '../../../../shared/services/actionItems/useApproveSeverityIncreasedMutation';
import { useDeactivationAiAnalystLogMutation } from '../../../../shared/services/actionItems/useDeactivationAiAnalystLogMutation';
import { useDeclineSeverityIncreaseMutation } from '../../../../shared/services/actionItems/useDeclineSeverityIncreasedMutation';
import { useSeverityIncreaseQuery } from '../../../../shared/services/actionItems/useSeverityIncreaseQuery';
import { selectIsEditSections, useActionItemViewActions, } from '../../../../shared/store/actionItem/useActionItemViewStore';
import '../../../../shared/styles/AIPreAi/index.scss';
import FooterTools from '../../../../widgets/FooterTools/FooterTools';
import FooterToolsToggleBtn from '../../../../widgets/FooterTools/components/ToggleBtn/ToogleBtn';
import Content from '../../../components/actionItem/view/Content';
import Header from '../../../components/actionItem/view/Header';
import { useActionItemQuery } from '../../../services/actionItems/useActionItemQuery';
var ActionItemViewPage = function () {
    var actionItemId = useParams().id;
    useTitle('Action Item');
    var _a = useActionItemQuery({ actionItemId: actionItemId }), actionItemData = _a.data, isLoading = _a.isLoading, isFetching = _a.isFetching, isError = _a.isError;
    var deactivationAiAnalystLogMutate = useDeactivationAiAnalystLogMutation({ storage: null }).mutate;
    useDeactivationAnalystLog(actionItemId, deactivationAiAnalystLogMutate);
    var isEditSections = selectIsEditSections();
    var setIsEditSections = useActionItemViewActions().setIsEditSections;
    var approveSeverityIncreaseMutate = useApproveSeverityIncreaseMutation({
        id: actionItemId,
        isPreAi: false,
        storage: null,
    }).mutate;
    var declineSeverityIncreaseMutate = useDeclineSeverityIncreaseMutation({
        id: actionItemId,
        isPreAi: false,
        storage: null,
    }).mutate;
    var hasSeverityIncrease = useSeverityIncreaseQuery({
        actionItemId: actionItemId,
    }).data;
    var confirmDeleteSeverityIncrease = useDeleteSeverityIncrease({
        actionItemId: Number(actionItemId),
        approveSeverityIncreaseMutate: approveSeverityIncreaseMutate,
        declineSeverityIncreaseMutate: declineSeverityIncreaseMutate,
        isPreAi: false,
        confirmationText: 'The current AI is set to Increase Severity, please process the current data before changing status',
    }).confirmDeleteSeverityIncrease;
    var deleteSeverityIncrease = function () {
        return new Promise(function (resolve) {
            if (hasSeverityIncrease) {
                return confirmDeleteSeverityIncrease({ onSuccess: function () { return resolve(); } });
            }
            resolve();
        });
    };
    return (_jsxs("div", { className: "page-actionItem", children: [(isLoading || isFetching) && (_jsx("div", { className: "loader", children: _jsx(Loader, { variant: "loader-lg" }) })), actionItemData && (_jsxs(_Fragment, { children: [_jsx(Header, { onBeforeStatusChange: deleteSeverityIncrease }), _jsxs("div", { className: "d-flex", children: [_jsx(Content, { onBeforeCompleteReport: deleteSeverityIncrease }), _jsx(ActivityLog, { actionItemData: actionItemData, onBeforeComment: deleteSeverityIncrease })] }), _jsx(FooterTools, { children: _jsx(FooterToolsToggleBtn, { isSelected: isEditSections, onClick: function () { return setIsEditSections(!isEditSections); }, children: _jsx(RiSortAsc, {}) }) })] })), isError && _jsx("p", { children: "Something went wrong!" })] }));
};
export default memo(ActionItemViewPage);
