var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaCheck, FaCheckCircle, FaTimes } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import RichTextEditor from '../../../../../../widgets/TextEditor/TextEditor';
import User from '../../../../../helper/user';
import useToastErrorAlert from '../../../../../hooks/useToastErrorAlert';
import { useActionItemModalsActions } from '../../../../../store/AIPreAi/useActionItemModalsStore';
import { useConfirmModalActions } from '../../../../../store/global/useConfirmModalStore';
import { actionItemReportFormsSchemaAccept, actionItemReportFormsSchemaClosed, actionItemReportFormsSchemaDecline, } from './schemas';
var processingFormText = {
    accept: 'WW Analyst Closing Comments:',
    decline: 'Declination reason:',
    close: 'The reason for closing Action Item:',
};
var ReportForms = function (_a) {
    var isNoActionReport = _a.isNoActionReport, isShowDeclineButton = _a.isShowDeclineButton, customerId = _a.customerId, acceptAIReportMutate = _a.acceptAIReportMutate, closeAIReportMutate = _a.closeAIReportMutate, declineAIReportMutate = _a.declineAIReportMutate, _b = _a.storage, storage = _b === void 0 ? null : _b, onSuccess = _a.onSuccess, onBeforeComplete = _a.onBeforeComplete;
    var actionItemId = useParams().id;
    if (!acceptAIReportMutate || !closeAIReportMutate || !declineAIReportMutate) {
        return _jsx(_Fragment, {});
    }
    var _c = useState(''), reportProcessingType = _c[0], setReportProcessingType = _c[1];
    var _d = useState(false), isVisibleReportProcessingForm = _d[0], setIsVisibleReportProcessingForm = _d[1];
    var getSchema = function () {
        if (reportProcessingType === 'close') {
            return actionItemReportFormsSchemaClosed;
        }
        if (reportProcessingType === 'accept') {
            return actionItemReportFormsSchemaAccept;
        }
        return actionItemReportFormsSchemaDecline;
    };
    var _e = useForm({
        defaultValues: {
            reportProcessingReason: '',
        },
        resolver: getSchema(),
    }), setValue = _e.setValue, errors = _e.formState.errors, handleSubmit = _e.handleSubmit, resetField = _e.resetField;
    var reportProcessingReasonRef = useRef('');
    useToastErrorAlert(errors);
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var setCloseReportModalState = useActionItemModalsActions().setCloseReportModalState;
    var onProcessReport = handleSubmit(function () { return __awaiter(void 0, void 0, void 0, function () {
        var afterSave;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (onBeforeComplete === null || onBeforeComplete === void 0 ? void 0 : onBeforeComplete())];
                case 1:
                    _a.sent();
                    afterSave = function () {
                        setIsVisibleReportProcessingForm(false);
                        resetField('reportProcessingReason');
                        onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                    };
                    if (reportProcessingType === 'decline') {
                        return [2 /*return*/, setConfirmData({
                                isVisible: true,
                                textConfirmationModal: "Are you sure you want to ".concat(reportProcessingType, " report?"),
                                onConfirm: function () {
                                    setConfirmData({
                                        isVisible: false,
                                    });
                                    return declineAIReportMutate({
                                        id: actionItemId,
                                        comment: reportProcessingReasonRef.current,
                                    }, {
                                        onSuccess: function () {
                                            toast.success('Successfully declined', {
                                                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                                            });
                                            afterSave();
                                        },
                                    });
                                },
                            })];
                    }
                    setCloseReportModalState({
                        visible: true,
                        reportProcessingType: reportProcessingType,
                        onSave: function (data) {
                            if (reportProcessingType === 'accept') {
                                return acceptAIReportMutate(__assign({ action_item_id: +actionItemId, report_comment: reportProcessingReasonRef.current }, data), {
                                    onSuccess: function () {
                                        toast.success('Successfully accept', {
                                            icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                                        });
                                        afterSave();
                                    },
                                });
                            }
                            if (reportProcessingType === 'close') {
                                return closeAIReportMutate(__assign({ action_list_id: +actionItemId, description: reportProcessingReasonRef.current }, data), {
                                    onSuccess: function () {
                                        toast.success('Successfully closed', {
                                            icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                                        });
                                        afterSave();
                                    },
                                });
                            }
                        },
                    });
                    return [2 /*return*/];
            }
        });
    }); });
    return (_jsxs(_Fragment, { children: [_jsx(Row, { children: _jsx(Col, { children: !isVisibleReportProcessingForm ? (_jsxs("div", { className: "mt-2 float-end", children: [User.can('reviewingCR') ? (_jsx(_Fragment, { children: isNoActionReport ? (_jsxs(Button, { onClick: function () {
                                        setIsVisibleReportProcessingForm(true);
                                        setReportProcessingType('close');
                                    }, color: "success", size: "sm", className: "me-1", children: [_jsx(FaCheck, {}), " Close"] })) : (_jsxs(Button, { onClick: function () {
                                        setIsVisibleReportProcessingForm(true);
                                        setReportProcessingType('accept');
                                    }, color: "success", size: "sm", className: "me-1", children: [_jsx(FaCheck, {}), " Accept"] })) })) : (_jsx(_Fragment, {})), isShowDeclineButton ? (_jsxs(Button, { onClick: function () {
                                    setIsVisibleReportProcessingForm(true);
                                    setReportProcessingType('decline');
                                }, color: "danger", size: "sm", children: [_jsx(FaTimes, {}), " Decline"] })) : (_jsx(_Fragment, {}))] })) : (_jsx(_Fragment, {})) }) }), _jsx(Row, { className: "mt-2", children: _jsx(Col, { children: isVisibleReportProcessingForm ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "fw-bold mb-2", children: processingFormText[reportProcessingType] }), _jsx("div", { className: "mt-2", children: _jsx(RichTextEditor, { storage: storage, customerId: customerId, content: reportProcessingReasonRef.current, onChange: function (content) { return (reportProcessingReasonRef.current = content); } }) }), _jsxs("div", { className: "mt-2 float-end", children: [_jsx(Button, { className: "me-1", color: "secondary", size: "sm", onClick: function () {
                                            setIsVisibleReportProcessingForm(false);
                                        }, children: "Cancel" }), _jsx(Button, { color: "success", size: "sm", onClick: function () {
                                            setValue('reportProcessingReason', reportProcessingReasonRef.current);
                                            onProcessReport();
                                        }, children: "Submit" })] })] })) : (_jsx(_Fragment, {})) }) })] }));
};
export default memo(ReportForms);
