import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import StatusLabelAmazon from '../amazon/components/AIPreAi/StatusLabel';
import { SYSTEM_TYPE_REMASTERED } from '../constants';
import StatusLabelRemastered from '../remastered/components/AIPreAi/StatusLabel';
import { SystemTypeContext } from '../shared/context/SystemTypeContext';
import { AiOnBedBadge, BadDataBadge, HardwareAiBadge, PreActionItemBadge, RegularAiBadge, WatchListBadge, } from './common';
export var InList = function (_a) {
    var entity = _a.entity, _b = _a.onClick, onClick = _b === void 0 ? function () { } : _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return (_jsxs("div", { className: "d-flex flex-wrap justify-content-center gap-2", children: [+entity.is_action_item_exist === 1 ? (selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsx(StatusLabelRemastered, { status: +entity.existing_action_item_state, commentsCount: 0, isTroubleshootingHold: entity.is_troubleshooting_hold, isTroubleshootingWaiting: entity.is_troubleshooting_waiting })) : (_jsx(StatusLabelAmazon, { status: +entity.existing_action_item_state, commentsCount: 0 }))) : (_jsx(_Fragment, {})), +entity.is_bad_data_exist === 1 && _jsx(BadDataBadge, {}), +entity.is_regular_action_item_exist === 1 && (_jsx(RegularAiBadge, { selectedSystemType: selectedSystemType, status: +entity.existing_item_state, is_troubleshooting_hold: +entity.is_troubleshooting_hold, is_troubleshooting_waiting: entity.is_troubleshooting_waiting })), +entity.is_ww_action_item_exist === 1 && _jsx(HardwareAiBadge, {}), +entity.is_pre_action_item_exist === 1 && _jsx(PreActionItemBadge, {}), +entity.is_watchlist_exist === 1 && _jsx(WatchListBadge, {}), +entity.is_ai_on_equipment === 1 && +entity.is_action_item_exist !== 1 && (_jsx(AiOnBedBadge, { onClick: onClick }))] }));
};
