import { useMemo } from 'react';
import { isNumeric } from '../../../../../../shared/helper/commonHelper';
export var useValidationErrors = function (_a) {
    var alertLevels = _a.alertLevels;
    var validationErrors = useMemo(function () {
        var errors = [];
        var firstPair = alertLevels[0];
        var secondPair = alertLevels[1];
        var firstCaution = firstPair.caution, firstWarning = firstPair.warning;
        var _a = secondPair || {}, secondCaution = _a.caution, secondWarning = _a.warning;
        // Saving 2 or more identical alert types (caution/warning/…) with the same alarm type is prohibited (< / >).
        // The user can create 4 different alarm level values ((above and below for caution and above and below for warning),
        // however, if the user tries to set two identical alert types with the same values, one will not be able to save the levels because it does not make sense.
        if ((isNumeric(firstCaution.level) &&
            isNumeric((secondCaution === null || secondCaution === void 0 ? void 0 : secondCaution.level) || '') &&
            firstCaution.condition === (secondCaution === null || secondCaution === void 0 ? void 0 : secondCaution.condition)) ||
            (isNumeric(firstWarning.level) &&
                isNumeric((secondWarning === null || secondWarning === void 0 ? void 0 : secondWarning.level) || '') &&
                firstWarning.condition === (secondWarning === null || secondWarning === void 0 ? void 0 : secondWarning.condition))) {
            errors.push('Identical alert types (caution/warning) with the same alarm type is prohibited');
        }
        {
            var message = 'The value of "Warning >" must be greater than "Caution >"';
            var rule = function (pair) {
                var caution = pair.caution, warning = pair.warning;
                if (isNumeric(caution.level) &&
                    isNumeric(warning.level) &&
                    warning.condition === '>=' &&
                    caution.condition === '>=' &&
                    +warning.level < +caution.level) {
                    return true;
                }
            };
            if (rule(firstPair) || (secondPair && rule(secondPair))) {
                errors.push(message);
            }
        }
        {
            var message = 'The value of "Caution <" must be greater than "Warning <"';
            var rule = function (pair) {
                var caution = pair.caution, warning = pair.warning;
                if (isNumeric(caution.level) &&
                    isNumeric(warning.level) &&
                    caution.condition === '<=' &&
                    warning.condition === '<=' &&
                    +caution.level < +warning.level) {
                    return true;
                }
            };
            if (rule(firstPair) || (secondPair && rule(secondPair))) {
                errors.push(message);
            }
        }
        {
            var message = 'The value of "Caution >" must be greater than "Caution <"';
            var rule = function (firstPair, secondPair) {
                if (isNumeric(firstPair.caution.level) &&
                    isNumeric(secondPair.caution.level) &&
                    firstPair.caution.condition === '>=' &&
                    secondPair.caution.condition === '<=' &&
                    +firstPair.caution.level < +secondPair.caution.level) {
                    return true;
                }
            };
            if (secondPair && rule(firstPair, secondPair)) {
                errors.push(message);
            }
        }
        {
            var message = 'The value of "Warning >" must be greater than "Warning <"';
            var rule = function (firstPair, secondPair) {
                if (isNumeric(firstPair.warning.level) &&
                    isNumeric(secondPair.warning.level) &&
                    firstPair.warning.condition === '>=' &&
                    secondPair.warning.condition === '<=' &&
                    +firstPair.warning.level < +secondPair.warning.level) {
                    return true;
                }
            };
            if (secondPair && rule(firstPair, secondPair)) {
                errors.push(message);
            }
        }
        {
            var message = 'The value of "Warning >" must be greater than "Caution <"';
            var rule = function (firstPair, secondPair) {
                if (isNumeric(firstPair.warning.level) &&
                    isNumeric(secondPair.caution.level) &&
                    firstPair.warning.condition === '>=' &&
                    secondPair.caution.condition === '<=' &&
                    +firstPair.warning.level < +secondPair.caution.level) {
                    return true;
                }
            };
            if (secondPair && rule(firstPair, secondPair)) {
                errors.push(message);
            }
        }
        {
            var message = 'The value of "Caution >" must be greater than "Warning <"';
            var rule = function (firstPair, secondPair) {
                if (isNumeric(firstPair.caution.level) &&
                    isNumeric(secondPair.warning.level) &&
                    firstPair.caution.condition === '>=' &&
                    secondPair.warning.condition === '<=' &&
                    +firstPair.caution.level < +secondPair.warning.level) {
                    return true;
                }
            };
            if (secondPair && rule(firstPair, secondPair)) {
                errors.push(message);
            }
        }
        return errors;
    }, [alertLevels]);
    return {
        validationErrors: validationErrors,
    };
};
