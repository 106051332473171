var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
export var onEdit = function (faultFrequency, chartIdentifier, setStatesChartFeaturesStore) {
    setStatesChartFeaturesStore({
        setFaultFrequency: {
            visible: true,
            openedByChartIdentifier: chartIdentifier,
            installationPointId: faultFrequency.installation_point_id,
            faultFrequency: {
                id: faultFrequency.id,
                frequency: faultFrequency.frequency,
                name: faultFrequency.name,
                plotLinesCount: faultFrequency.plot_lines_count,
                frequencyType: faultFrequency.frequency_type,
            },
        },
    });
};
export var onSetFaultFrequency = function (setStatesChartFeaturesStore, chartIdentifier, installationPointId) {
    setStatesChartFeaturesStore({
        setFaultFrequency: {
            visible: true,
            openedByChartIdentifier: chartIdentifier,
            installationPointId: installationPointId,
            faultFrequency: {},
        },
    });
};
export var onRemove = function (id, faultFrequencies, setLoader, setConfirmData, setStatesFftChartsStore, endpoint) {
    setConfirmData({
        isVisible: true,
        textConfirmationModal: 'Are you sure you want to delete fault/forcing frequency?',
        onConfirm: function () {
            setConfirmData({
                isVisible: false,
            });
            setLoader(true);
            endpoint(id).then(function (resp) {
                setLoader(false);
                if (resp) {
                    if (resp.success) {
                        setStatesFftChartsStore({
                            faultFrequencies: faultFrequencies.filter(function (faultFrequency) { return +faultFrequency.id !== +id; }),
                        });
                        return toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                    }
                    return toast.error(resp.message, { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
                }
            });
        },
    });
};
export var fetch = function (config, setConfig, endpoint, setLoader) {
    setLoader(true);
    endpoint().then(function (resp) {
        setLoader(false);
        if (resp) {
            setConfig(__assign(__assign({}, config), { data: resp.data }));
        }
    });
};
