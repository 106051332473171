var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQueryClient as useQueryClientUntyped } from '@tanstack/react-query';
import { memo } from 'react';
import { RiSortAsc } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { useTitle } from '../../../../customHooks';
import Loader from '../../../../shared/components/Loader';
import ActivityLog from '../../../../shared/components/actionItem/activityLog';
import useDeactivationAnalystLog from '../../../../shared/hooks/AIPreAi/useDeactivationAnalystLog';
import { useDeleteSeverityIncrease } from '../../../../shared/hooks/actionItem/useDeleteSeverityIncrease';
import ReportEditorEntityEditModal from '../../../../shared/modals/reportEditor/EntityEditModal';
import { useApproveSeverityIncreaseMutation } from '../../../../shared/services/actionItems/useApproveSeverityIncreasedMutation';
import { useDeactivationAiAnalystLogMutation } from '../../../../shared/services/actionItems/useDeactivationAiAnalystLogMutation';
import { useDeclineSeverityIncreaseMutation } from '../../../../shared/services/actionItems/useDeclineSeverityIncreasedMutation';
import { useSeverityIncreaseQuery } from '../../../../shared/services/actionItems/useSeverityIncreaseQuery';
import { selectReportEditorEntityEditState, useActionItemModalsActions, } from '../../../../shared/store/AIPreAi/useActionItemModalsStore';
import { selectIsEditSections, useActionItemViewActions, } from '../../../../shared/store/actionItem/useActionItemViewStore';
import '../../../../shared/styles/AIPreAi/index.scss';
import FooterTools from '../../../../widgets/FooterTools/FooterTools';
import FooterToolsToggleBtn from '../../../../widgets/FooterTools/components/ToggleBtn/ToogleBtn';
import Content from '../../../components/actionItem/view/Content';
import Header from '../../../components/actionItem/view/Header';
import { actionItemKey, useActionItemQuery, } from '../../../services/actionItems/useActionItemQuery';
import { useToggleCustomerRequestMutation } from '../../../services/actionItems/useCustomerRequestMutation';
var useQueryClient = useQueryClientUntyped;
var ActionItemViewPage = function () {
    var queryClient = useQueryClient();
    var actionItemId = useParams().id;
    useTitle('Action Item');
    var _a = useActionItemQuery({ actionItemId: actionItemId }), actionItemData = _a.data, isLoading = _a.isLoading, isFetching = _a.isFetching, isError = _a.isError;
    var _b = actionItemData || {}, _c = _b.storage, storage = _c === void 0 ? null : _c, _d = _b.isCustomerCreated, isCustomerCreated = _d === void 0 ? false : _d, _e = _b.isCustomerRequested, isCustomerRequested = _e === void 0 ? false : _e;
    var deactivationAiAnalystLogMutate = useDeactivationAiAnalystLogMutation({
        storage: storage !== null && storage !== void 0 ? storage : null,
    }).mutate;
    var reportEditorEntityEditState = selectReportEditorEntityEditState();
    var setReportEditorEntityEditState = useActionItemModalsActions().setReportEditorEntityEditState;
    useDeactivationAnalystLog(actionItemId, deactivationAiAnalystLogMutate);
    var isEditSections = selectIsEditSections();
    var setIsEditSections = useActionItemViewActions().setIsEditSections;
    var toggleCustomerRequest = useToggleCustomerRequestMutation({
        actionItemId: actionItemId,
        isCustomerCreated: isCustomerCreated,
    }, {
        onSuccess: function () {
            if (actionItemId) {
                queryClient.invalidateQueries({ queryKey: actionItemKey(actionItemId) });
            }
        },
    }).mutate;
    var deleteCustomerRequestTag = function () {
        if (isCustomerCreated && isCustomerRequested) {
            toggleCustomerRequest(0);
        }
    };
    var approveSeverityIncreaseMutate = useApproveSeverityIncreaseMutation({
        id: actionItemId,
        isPreAi: false,
        storage: storage,
    }).mutate;
    var declineSeverityIncreaseMutate = useDeclineSeverityIncreaseMutation({
        id: actionItemId,
        isPreAi: false,
        storage: storage,
    }).mutate;
    var hasSeverityIncrease = useSeverityIncreaseQuery({
        actionItemId: actionItemId,
    }).data;
    var confirmDeleteSeverityIncrease = useDeleteSeverityIncrease({
        actionItemId: Number(actionItemId),
        approveSeverityIncreaseMutate: approveSeverityIncreaseMutate,
        declineSeverityIncreaseMutate: declineSeverityIncreaseMutate,
        isPreAi: false,
        confirmationText: 'The current AI is set to Increase Severity, please process the current data before changing status',
    }).confirmDeleteSeverityIncrease;
    var deleteSeverityIncrease = function () {
        return new Promise(function (resolve) {
            if (hasSeverityIncrease) {
                return confirmDeleteSeverityIncrease({ onSuccess: function () { return resolve(); } });
            }
            resolve();
        });
    };
    return (_jsxs("div", { className: "page-actionItem", children: [(isLoading || isFetching) && (_jsx("div", { className: "loader", children: _jsx(Loader, { variant: "loader-lg" }) })), actionItemData && (_jsxs(_Fragment, { children: [_jsx(Header, { onBeforeStatusChange: deleteSeverityIncrease }), _jsxs("div", { className: "d-flex", children: [_jsx(Content, { onBeforeCompleteReport: deleteSeverityIncrease, onDeleteCustomerRequestTag: deleteCustomerRequestTag }), _jsx(ActivityLog, { actionItemData: actionItemData, storage: storage, onSuccessSaveComment: deleteCustomerRequestTag, onBeforeComment: deleteSeverityIncrease }), _jsx(ReportEditorEntityEditModal, { reportEditorEntityEditState: reportEditorEntityEditState, onCancel: function () {
                                    return setReportEditorEntityEditState(__assign(__assign({}, reportEditorEntityEditState), { visible: false }));
                                } })] }), _jsx(FooterTools, { children: _jsx(FooterToolsToggleBtn, { isSelected: isEditSections, onClick: function () { return setIsEditSections(!isEditSections); }, children: _jsx(RiSortAsc, {}) }) })] })), isError && _jsx("p", { children: "Something went wrong!" })] }));
};
export default memo(ActionItemViewPage);
