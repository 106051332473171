import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import StatusLabelAmazon from '../../../../amazon/components/AIPreAi/StatusLabel';
import { UrgencyLabel as UrgencyLabelAmazon } from '../../../../amazon/components/AIPreAi/UrgencyLabel';
import { ACTION_LIST, SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import StatusLabelRemastered from '../../../../remastered/components/AIPreAi/StatusLabel';
import { UrgencyLabel as UrgencyLabelRemastered } from '../../../../remastered/components/AIPreAi/UrgencyLabel';
import { DateTimeLabel } from '../../../../widgets/common';
import { PreStatusLabel } from '../../../../widgets/preActionItems';
import { SensorId } from '../../../../widgets/sensor';
import { onViewActionItem, onViewPreActionItem } from '../../../features/shared';
import { highlightRow } from '../../../helper/table';
export var initialState = function (selectedSystemType, isPreAi) {
    return {
        config: {
            columns: [
                {
                    name: 'equipment_name',
                    title: 'Equipment',
                    sortable: false,
                    component: function (row) {
                        return row.equipment_info || row.equipment_name || '---';
                    },
                },
                {
                    name: 'point_name',
                    title: 'Point name',
                    sortable: false,
                    component: function (row) {
                        if (row.equipment_type === ACTION_LIST.EQUIPMENT_TYPE_SENSOR ||
                            row.equipment_type === ACTION_LIST.EQUIPMENT_TYPE_MOTE) {
                            return (_get(row, 'instPoint.name') ||
                                _get(row, 'installationPoint.name') ||
                                _get(ACTION_LIST.EQUIPMENT_TYPES_NAME_MAPPING, row.equipment_type) ||
                                '---');
                        }
                        return _get(ACTION_LIST.EQUIPMENT_TYPES_NAME_MAPPING, row.equipment_type);
                    },
                },
                {
                    name: 'sensor_id',
                    title: 'Serial/Sensor ID',
                    sortable: false,
                    component: function (row) {
                        return (_jsxs("div", { className: "no-wrap", children: [row.serial || '', row.serial ? '/' : '', _jsx(SensorId, { sensor_id: +(row === null || row === void 0 ? void 0 : row.sensor_id) })] }));
                    },
                },
                {
                    name: 'time_created',
                    title: 'Date created',
                    sortable: false,
                    component: function (row) {
                        return _jsx(DateTimeLabel, { id: "ai_list_time_created_".concat(row.id), dateTime: row.time_created });
                    },
                },
                {
                    name: 'created_by',
                    title: 'Added by',
                    sortable: false,
                },
                {
                    name: 'updated_at',
                    title: 'Last Update',
                    sortable: false,
                    component: function (row) {
                        return _jsx(DateTimeLabel, { id: "ai_list_updated_at_".concat(row.id), dateTime: row.updated_at });
                    },
                },
                {
                    title: 'Urgency',
                    name: 'urgency',
                    sortable: false,
                    component: function (row) {
                        return selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsx(UrgencyLabelRemastered, { urgency: +row.urgency })) : (_jsx(UrgencyLabelAmazon, { urgency: +row.urgency, type: null }));
                    },
                },
                {
                    title: 'Status',
                    name: 'status',
                    sortable: false,
                    component: function (row) {
                        return isPreAi ? (_jsx(PreStatusLabel, { status: +row.status, isTech: row.source === 'installapp' })) : (_jsx(_Fragment, { children: selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsx(StatusLabelRemastered, { isTroubleshootingHold: +row.is_troubleshooting_hold, isTroubleshootingWaiting: +row.is_troubleshooting_waiting, status: +row.state, commentsCount: +row.comments_count })) : (_jsx(StatusLabelAmazon, { status: +row.state, commentsCount: +row.comments_count, isTroubleshootingHoldWaiting: +row.is_troubleshooting_waiting })) }));
                    },
                },
                {
                    name: 'actions',
                    sortable: false,
                    component: function (row) {
                        return (_jsxs(Button, { color: "primary", className: "me-2 position-relative", size: "sm", onClick: function (ev) {
                                isPreAi
                                    ? onViewPreActionItem(row.id, selectedSystemType)
                                    : onViewActionItem(row.id, selectedSystemType);
                                highlightRow(ev);
                            }, children: ["View", row.type === ACTION_LIST.AI_TYPE_WW ? (_jsxs(React.Fragment, { children: [_jsx("span", { className: "sub-badge sub-badge-letter", id: "w_".concat(row.id), children: "W" }), _jsx(UncontrolledTooltip, { placement: "left", target: "w_".concat(row.id), children: "Action required on Waites equipment attached to this monitored point" })] })) : (_jsx(React.Fragment, { children: +row.is_replace_equipment === 1 ? (_jsxs(React.Fragment, { children: [_jsx("span", { id: "r_".concat(row.id), className: "sub-badge sub-badge-letter", children: "R" }), _jsx(UncontrolledTooltip, { placement: "left", target: "r_".concat(row.id), children: "This Action Item require replacing Waites equipment" })] })) : (_jsx(_Fragment, {})) }))] }));
                    },
                },
            ],
            sort: [
                {
                    field: 'updated_at',
                    direction: 'desc',
                },
            ],
            pagination: {
                disabled: true,
            },
            hideTotalLabel: true,
            search: '',
            loader: true,
            disabledSearch: true,
            data: [],
        },
    };
};
