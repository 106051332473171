import { size as _size } from 'lodash';
import { DEFAULT_ALERT_CONDITION } from './constants';
export var transformLevelsObjectToArray = function (object) {
    if (_size(object.conditions)) {
        return Object.keys(object.conditions).map(function (key) {
            var levels = {
                caution: structuredClone(DEFAULT_ALERT_CONDITION),
                warning: structuredClone(DEFAULT_ALERT_CONDITION),
            };
            Object.keys(object.conditions[key]).forEach(function (level) {
                var levelKey = level;
                levels[level] = {
                    id: key,
                    condition: object.conditions[key][level],
                    fftSettings: object.fftSettings[key][level],
                    isRequestFFT: Number(Object.values(object.fftSettings[key][level]).every(Boolean)),
                    level: String(object.storedLevels[key][level] || ''),
                    settings: object.settings[key][level],
                };
                var levelValue = object.currentLevel[levelKey];
                if (object.currentLevel.id === key && levelValue) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    levels[levelKey].level = Number(levelValue).toFixed(2).toString();
                }
            });
            return levels;
        });
    }
    var alertLevels = {
        caution: structuredClone(DEFAULT_ALERT_CONDITION),
        warning: structuredClone(DEFAULT_ALERT_CONDITION),
    };
    var _a = object.currentLevel, caution = _a.caution, warning = _a.warning;
    if (caution && alertLevels.caution) {
        alertLevels.caution.level = Number(caution).toFixed(2);
    }
    if (warning && alertLevels.warning) {
        alertLevels.warning.level = Number(warning).toFixed(2);
    }
    return [alertLevels];
};
export var transformLevelsArrayToObject = function (array) {
    return array.reduce(function (acc, alertLevel) {
        var caution = alertLevel.caution, warning = alertLevel.warning;
        var alertLevelId = (caution === null || caution === void 0 ? void 0 : caution.id) || (warning === null || warning === void 0 ? void 0 : warning.id) || Math.random().toString();
        if (!acc.conditions[alertLevelId]) {
            acc.conditions[alertLevelId] = {};
            acc.levels[alertLevelId] = {};
        }
        if (caution) {
            acc.conditions[alertLevelId].caution = caution.condition;
            acc.levels[alertLevelId].caution = caution.level;
        }
        if (warning) {
            acc.conditions[alertLevelId].warning = warning.condition;
            acc.levels[alertLevelId].warning = warning.level;
        }
        return acc;
    }, {
        conditions: {},
        levels: {},
    });
};
