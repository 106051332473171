import { size as _size } from 'lodash';
import { getSoftMax, getSoftMin, isCfChart } from '../../../../../helper/chart';
import { isNumeric } from '../../../../../helper/commonHelper';
import '../../../../../styles/charts/chartsWrapper/alertConditions.css';
export var calculateCurrentLevel = function (chartIdentifier, alertConditions, readingTypes, measure, personalSettingMeasure) {
    var _a, _b;
    var precision = (_b = (_a = readingTypes[chartIdentifier]) === null || _a === void 0 ? void 0 : _a.precision) !== null && _b !== void 0 ? _b : 2;
    var maxY = getSoftMax(chartIdentifier, measure, personalSettingMeasure);
    if (_size((alertConditions === null || alertConditions === void 0 ? void 0 : alertConditions.levels) || null)) {
        return Object.entries(alertConditions.levels).map(function (_a) {
            var id = _a[0], _b = _a[1], caution = _b.caution, warning = _b.warning;
            return ({
                caution: isNumeric(caution) ? parseFloat(String(caution)).toFixed(precision) : null,
                warning: isNumeric(warning) ? parseFloat(String(warning)).toFixed(precision) : null,
                cautionCondition: alertConditions.conditions[id].caution,
                warningCondition: alertConditions.conditions[id].warning,
                id: id,
            });
        });
    }
    return [
        {
            caution: maxY * 1.1,
            warning: maxY * 1.2,
            cautionCondition: '',
            warningCondition: '',
            id: null,
        },
    ];
};
export var getAlertConditionsConfig = function (alertConditions, chartIdentifier, setStatesChartFeaturesStore, readingTypes, measure, personalSettingMeasure, isDraggable, isSelectedChartActive) {
    if (isDraggable === void 0) { isDraggable = true; }
    if (isSelectedChartActive === void 0) { isSelectedChartActive = false; }
    if (isCfChart(chartIdentifier)) {
        return [];
    }
    var hasLevels = Boolean((alertConditions === null || alertConditions === void 0 ? void 0 : alertConditions.levels) && Object.values(alertConditions.levels).length);
    var currentLevels = calculateCurrentLevel(chartIdentifier, alertConditions, readingTypes, measure, personalSettingMeasure);
    var additionalClass = !hasLevels || (!isDraggable && !isSelectedChartActive) ? ' not-exist' : '';
    var minY = getSoftMin(chartIdentifier, measure, personalSettingMeasure);
    var plotLines = currentLevels
        .map(function (level) { return [
        {
            className: "alarm-plot-line caution ".concat(additionalClass),
            value: level.caution,
            zIndex: 4,
            events: {
                mousedown: function (e) {
                    if (!isDraggable) {
                        return;
                    }
                    onMoveAlertConditionLine(e, this, level, minY, chartIdentifier, setStatesChartFeaturesStore, 'caution', alertConditions, readingTypes);
                },
            },
        },
        {
            className: "alarm-plot-line warning ".concat(additionalClass),
            value: level.warning,
            zIndex: 4,
            events: {
                mousedown: function (e) {
                    if (!isDraggable) {
                        return;
                    }
                    onMoveAlertConditionLine(e, this, level, minY, chartIdentifier, setStatesChartFeaturesStore, 'warning', alertConditions, readingTypes);
                },
            },
        },
    ]; })
        .flat(1);
    if (alertConditions.levels && alertConditions.levels.startupCaution) {
        plotLines.push({
            events: { mousedown: function () { } },
            className: 'alarm-plot-line startup-plot-line caution' + additionalClass,
            value: alertConditions.levels.startupCaution,
            zIndex: 4,
        });
    }
    if (alertConditions.levels && alertConditions.levels.startupWarning) {
        plotLines.push({
            events: { mousedown: function () { } },
            className: 'alarm-plot-line startup-plot-line warning' + additionalClass,
            value: alertConditions.levels.startupWarning,
            zIndex: 4,
        });
    }
    if (_size(alertConditions.system) > 0) {
        if (alertConditions.system.caution) {
            plotLines.push({
                className: 'alarm-plot-line-system caution ',
                value: alertConditions.system.caution,
                zIndex: 4,
                // @ts-ignore
                isSystem: true,
                color: 'rgb(236, 151, 31)',
                width: isDraggable ? 1 : 2,
            });
        }
        if (alertConditions.system.warning) {
            plotLines.push({
                className: 'alarm-plot-line-system warning ',
                value: alertConditions.system.warning,
                zIndex: 4,
                // @ts-ignore
                isSystem: true,
                color: 'rgb(255, 0, 0)',
                width: isDraggable ? 1 : 2,
            });
        }
    }
    if (_size(alertConditions.ml_suggested_levels) > 0) {
        if (alertConditions.ml_suggested_levels.caution) {
            plotLines.push({
                className: 'alarm-plot-line-system caution ',
                value: alertConditions.ml_suggested_levels.caution,
                zIndex: 4,
                // @ts-ignore
                isSystem: true,
                color: 'rgb(236, 151, 31)',
                width: isDraggable ? 1 : 2,
            });
        }
        if (alertConditions.ml_suggested_levels.warning) {
            plotLines.push({
                className: 'alarm-plot-line-system warning ',
                value: alertConditions.ml_suggested_levels.warning,
                zIndex: 4,
                // @ts-ignore
                isSystem: true,
                color: 'rgb(255, 0, 0)',
                width: isDraggable ? 1 : 2,
            });
        }
    }
    return plotLines;
};
var onMoveAlertConditionLine = function (e, lineObj, currentLevel, minY, readingTypeId, setStatesChartFeaturesStore, type, alertConditions, readingTypes) {
    if (type === void 0) { type = 'caution'; }
    if (e.ctrlKey || e.metaKey) {
        return;
    }
    var axis = lineObj.axis;
    // eslint-disable-next-line prefer-const
    var line, clickY, newVal;
    newVal = currentLevel[type];
    var start = function (e) {
        // @ts-ignore
        $(document).bind({
            'mousemove.line': step,
            'mouseover.line': step,
            'mouseup.line': stop,
            'click.line': stop,
        });
        clickY = e.pageY - line.translateY;
    };
    var step = function (e) {
        newVal = axis.toValue(e.pageY - clickY + axis.toPixels(lineObj.options ? lineObj.options.value : 0));
        if (newVal > minY * 0.9) {
            return line.translate(0, e.pageY - clickY);
        }
        minY = newVal * 0.85;
    };
    var stop = function () {
        // @ts-ignore
        var val = parseFloat(newVal).toFixed(readingTypes[readingTypeId] ? readingTypes[readingTypeId].precision : 2);
        // @ts-ignore
        currentLevel[type] = val === '-0.00' || val === '0.00' ? 0 : val;
        // @ts-ignore
        $(document).unbind('.line');
        setStatesChartFeaturesStore({
            changeAlertConditions: {
                readingTypeId: readingTypeId,
                currentLevel: currentLevel,
                conditions: alertConditions.conditions,
                settings: alertConditions.settings,
                fftSettings: alertConditions.fftSettings,
                isRmsOd: alertConditions.isRmsOd,
                storedLevels: alertConditions.levels,
                isVisibleModal: true,
                shouldUpdate: false,
                ids: alertConditions.ids,
            },
        });
    };
    line = lineObj.svgElem.css({ cursor: 'pointer' });
    if (typeof line.translateY === 'undefined') {
        line.translate(0, 0);
    }
    start(e);
};
export var getChartAlarmBorder = function (alertsTriggered, customerAlertLevels, chartIdentifier) {
    var cautionAlarm = '0 0 10px 1px';
    var warningAlarm = '0 0 10px 1px';
    var alarmBorderColor = 'none';
    customerAlertLevels.map(function (alertLevel) {
        if (alertLevel.name === 'Caution') {
            cautionAlarm = "".concat(cautionAlarm, " ").concat(alertLevel.color);
        }
        if (alertLevel.name === 'Warning') {
            warningAlarm = "".concat(warningAlarm, " ").concat(alertLevel.color);
        }
    });
    Object.entries(alertsTriggered).map(function (_a) {
        var key = _a[0], value = _a[1];
        if (+chartIdentifier === +key) {
            alarmBorderColor = value == 1 ? cautionAlarm : warningAlarm;
        }
    });
    return { boxShadow: "".concat(alarmBorderColor) };
};
