import { jsx as _jsx } from "react/jsx-runtime";
import { has as _has } from 'lodash';
import React from 'react';
import { Button } from 'reactstrap';
import StatusLabelAmazon from '../../../../amazon/components/AIPreAi/StatusLabel';
import { SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import StatusLabelRemastered from '../../../../remastered/components/AIPreAi/StatusLabel';
import { UrgencyLabel } from '../../../../remastered/components/AIPreAi/UrgencyLabel';
import { DateTimeLabel } from '../../../../widgets/common';
import { SensorId } from '../../../../widgets/sensor';
import LinkWithPrefix from '../../../components/LinkWithPrefix';
import { translateByKey } from '../../../features/shared';
export var config = function (visibleState, selectedSystemType) {
    return {
        columns: [
            {
                name: 'title',
                visible: _has(visibleState, 'title') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.title : true,
                title: 'Title',
                sortable: false,
                component: function (row) {
                    return translateByKey(row, 'translates.en.title.text', row.title);
                },
            },
            {
                name: 'sla',
                visible: _has(visibleState, 'sla') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.sla : true,
                title: 'SLA',
                sortable: false,
            },
            {
                name: 'sensor_id',
                visible: _has(visibleState, 'sensor_id') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.sensor_id : true,
                title: 'Sensor ID',
                sortable: false,
                component: function (row) {
                    return _jsx(SensorId, { sensor_id: +row.sensor_id, hexBreak: true });
                },
            },
            {
                name: 'updated_at',
                visible: _has(visibleState, 'updated_at') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.updated_at : true,
                title: 'Last Update',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "customer_created_widget_".concat(row.id), dateTime: row.updated_at, timeBreak: true }));
                },
            },
            {
                title: 'Urgency',
                name: 'urgency',
                visible: _has(visibleState, 'urgency') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.urgency : true,
                sortable: false,
                component: function (row) {
                    return _jsx(UrgencyLabel, { urgency: +row.urgency });
                },
            },
            {
                title: 'Status',
                name: 'status',
                visible: _has(visibleState, 'status') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.status : true,
                sortable: false,
                component: function (row) {
                    return selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsx(StatusLabelRemastered, { decline: row.decline_reason, commentsCount: +row.comments_count, isTroubleshootingHold: +row.is_troubleshooting_hold, isTroubleshootingWaiting: +row.is_troubleshooting_waiting, userId: +row.user_id, status: +row.state })) : (_jsx(StatusLabelAmazon, { status: +row.state, isTroubleshootingHoldWaiting: +row.is_troubleshooting_waiting, commentsCount: +row.comments_count }));
                },
            },
            {
                name: 'actions',
                visible: _has(visibleState, 'actions') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.actions : true,
                sortable: false,
                component: function (row) {
                    return (_jsx(LinkWithPrefix, { to: "/action-item/".concat(row.id), target: "_blank", children: _jsx(Button, { color: "primary", size: "sm", children: "View" }) }));
                },
            },
        ],
        sort: [
            {
                field: 'updated_at',
                direction: 'desc',
            },
        ],
        pagination: {
            disabled: true,
        },
        disabledSearch: true,
        data: [],
        loader: false,
        hideTotalLabel: true,
        noDataLabel: 'There are no data',
    };
};
