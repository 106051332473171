var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get, size as _size } from 'lodash';
import moment from 'moment';
import React, { memo, useContext, useEffect, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../../api/endpoints/endpoints';
import { AXIS_Z, CHART_DATA_GROUPS, DEFAULT_READING_CONFIG, IMPACT_VUE_CHART_LIST, IMPACT_VUE_FFT_TWF_CHART_LIST, METRIC_KEY, READING_TYPE_FFT_ACCELERATION_X, READING_TYPE_FFT_ACCELERATION_Y, READING_TYPE_FFT_VELOCITY_X, READING_TYPE_FFT_VELOCITY_Y, READING_TYPE_TWF_ACCELERATION_X, READING_TYPE_TWF_ACCELERATION_Y, READING_TYPE_TWF_ACCELERATION_Z, READING_TYPE_TWF_VELOCITY_X, READING_TYPE_TWF_VELOCITY_Y, getDataGroup, } from '../../../../constants';
import '../../../../css/charts.css';
import { usePrevious } from '../../../../customHooks';
import ChangeAlarmLevelsModal from '../../../../modals/changeAlarmLevels/ChangeAlarmLevels';
import ClosestFft from '../../../../modals/closestFft/ClosestFft';
import HidePeaksModal from '../../../../modals/hidePeaks/HidePeaks';
import RemovePointModal from '../../../../modals/removePoint/RemovePoint';
import EquipmentSpeedModal from '../../../../modals/runningSpeed/equipmentSpeed/EquipmentSpeed';
import SetRunningSpeedModal from '../../../../modals/runningSpeed/setRunningSpeed/SetRunningSpeed';
import SuggestedLevelFormulaModal from '../../../../remastered/modals/suggestedLevelFormula';
import { isSupportZAxis } from '../../../../widgets/sensor';
import { CONTROL_KEY_NAME, META_KEY_NAME, SHIFT_KEY_NAME } from '../../../constants/hotkeys';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { isSortablePoint } from '../../../features/charts/shared';
import { getTimezone } from '../../../features/shared';
import { findClosestTimestamp, isFftTwfChart, isFftTwfChartImpactVue, setActiveChartOnClick, } from '../../../helper/chart';
import { initFeatures } from '../../../helper/highchart-extra-features';
import { useChartHotkey } from '../../../hooks/hotkey/useChartHotkey';
import BandsFormModal from '../../../modals/Bands';
import EnvelopeFormModal from '../../../modals/Envelope';
import CustomBearingModal from '../../../modals/bearings/CustomBearing';
import ManageBearingsModal from '../../../modals/bearings/ManageBearings';
import ManageFaultFrequenciesModal from '../../../modals/faultFrequencies/ManageFaultFrequencies';
import SetFaultFrequencyModal from '../../../modals/faultFrequencies/SetFaultFrequency';
import SetIdleThresholdModal from '../../../modals/setIdleThreshold/SetIdleThreshold';
import WaterfallPlotModal from '../../../modals/waterfallPlot';
import { ChartFeaturesStore } from '../../../store/charts/chartsContent/ChartFeaturesStore';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { FftChartsStore } from '../../../store/charts/chartsContent/FftChartsStore';
import { FftImpactVueChartsStore } from '../../../store/charts/chartsContent/FftImpactVueChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import { LeftEquipmentListStore } from '../../../store/charts/leftEquipmentList/LeftEquipmentListStore';
import { selectChartSorting } from '../../../store/charts/useChartSettingsStore';
import { selectMeasure, selectPersonalSettingMeasure } from '../../../store/global/useGlobalStore';
import '../../../styles/charts/chartsWrapper/cursor.css';
import '../../../styles/charts/index.scss';
import PaintingLeftBar from '../components/PaintingLeftBar';
import FftTwfChart from './FftTwfChart';
import FftTwfChartImpactVue from './FftTwfChartImpactVue';
import RmsChart from './RmsChart';
import { fetchFftData } from './components/shared/chartToolsButtons/FftTwfCalendar';
initFeatures();
var ChartsWrapper = function () {
    var _a;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = ChartsStore(function (state) { return ({
        chartView: state.chartView,
        setStates: state.setStates,
    }); }, shallow), chartView = _b.chartView, setStates = _b.setStates;
    var _c = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        readingTypes: state.readingTypes,
        isSupportHfdvue: state.isSupportHfdvue,
    }); }, shallow), installationPoint = _c.installationPoint, readingTypes = _c.readingTypes, isSupportHfdvue = _c.isSupportHfdvue;
    var sensor = _get(installationPoint, 'sensor') || {};
    var _d = ChartFeaturesStore(function (state) { return ({
        changeAlertConditions: state.changeAlertConditions,
        removePoint: state.removePoint,
        closestFft: state.closestFft,
        setRunningSpeed: state.setRunningSpeed,
        equipmentSpeedVisible: state.equipmentSpeedVisible,
        setFaultFrequency: state.setFaultFrequency,
        stateSuggestedLevelFormulaModal: state.stateSuggestedLevelFormulaModal,
        hidePeaksModal: state.hidePeaksModal,
        bandAlarmsFormModal: state.bandAlarmsFormModal,
        envelopeAlarmsFormModal: state.envelopeAlarmsFormModal,
        isVisibleManageBearings: state.isVisibleManageBearings,
        customBearingModal: state.customBearingModal,
        isVisibleIdleThresholdModal: state.isVisibleIdleThresholdModal,
        isVisibleManageFaultFrequencies: state.isVisibleManageFaultFrequencies,
        waterfallPlotModal: state.waterfallPlotModal,
        setStatesChartFeaturesStore: state.setStates,
        interpolationDisabled: state.interpolationDisabled,
    }); }, shallow), changeAlertConditions = _d.changeAlertConditions, removePoint = _d.removePoint, closestFft = _d.closestFft, setRunningSpeed = _d.setRunningSpeed, equipmentSpeedVisible = _d.equipmentSpeedVisible, setFaultFrequency = _d.setFaultFrequency, stateSuggestedLevelFormulaModal = _d.stateSuggestedLevelFormulaModal, hidePeaksModal = _d.hidePeaksModal, bandAlarmsFormModal = _d.bandAlarmsFormModal, envelopeAlarmsFormModal = _d.envelopeAlarmsFormModal, isVisibleManageBearings = _d.isVisibleManageBearings, customBearingModal = _d.customBearingModal, isVisibleManageFaultFrequencies = _d.isVisibleManageFaultFrequencies, isVisibleIdleThresholdModal = _d.isVisibleIdleThresholdModal, setStatesChartFeaturesStore = _d.setStatesChartFeaturesStore, waterfallPlotModal = _d.waterfallPlotModal, interpolationDisabled = _d.interpolationDisabled;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var equipments = LeftEquipmentListStore(function (state) { return ({
        equipments: state.equipments,
    }); }, shallow).equipments;
    var _e = FftChartsStore(function (state) { return ({
        fetchFftReadings: state.fetchFftReadings,
        data: state.data,
        fftSelectedDate: state.fftSelectedDate,
        fftTimestampDates: state.fftTimestampDates,
        readings: state.readings,
        fftLastSelectedDate: state.fftLastSelectedDate,
        setStatesFftChartsStore: state.setStatesFftChartsStore,
    }); }, shallow), fetchFftReadings = _e.fetchFftReadings, data = _e.data, fftSelectedDate = _e.fftSelectedDate, fftTimestampDates = _e.fftTimestampDates, readings = _e.readings, fftLastSelectedDate = _e.fftLastSelectedDate, setStatesFftChartsStore = _e.setStatesFftChartsStore;
    var _f = FftImpactVueChartsStore(function (state) { return ({
        fftSelectedDate: state.fftSelectedDate,
        data: state.data,
        lastTimestamps: state.lastTimestamps,
        fftSelectedDateChartIdentifier: state.fftSelectedDateChartIdentifier,
        fftTimestampDates: state.fftTimestampDates,
        setStatesFftChartsStore: state.setStatesFftChartsStore,
        fetchFftReadings: state.fetchFftReadings,
    }); }, shallow), fftSelectedDateImpactVue = _f.fftSelectedDate, dataImpactVue = _f.data, lastTimestamps = _f.lastTimestamps, fftImpactVueSelectedDateChartIdentifier = _f.fftSelectedDateChartIdentifier, fftTimestampDatesImpactVue = _f.fftTimestampDates, setStatesFftImpactVueChartsStore = _f.setStatesFftChartsStore, fetchFftImpactVueReadings = _f.fetchFftReadings;
    var isAvailableEquipments = useMemo(function () { return equipments.length > 0; }, [equipments]);
    var prevActiveInstallationPointId = usePrevious(installationPoint.id);
    var isEnabledImpactVue = +(sensor === null || sensor === void 0 ? void 0 : sensor.is_hfdvue) === 1;
    useEffect(function () {
        if (installationPoint.id && isEnabledImpactVue) {
            var timezone_1 = getTimezone(installationPoint, selectedSystemType);
            var readingFftId_1 = lastTimestamps ? lastTimestamps.readingFFT_id : null;
            if (!readingFftId_1) {
                setStatesFftImpactVueChartsStore({
                    loader: false,
                });
                return;
            }
            fetchFftImpactVueReadings(function () {
                return Endpoints[selectedSystemType].getFft(readingFftId_1, installationPoint.id, {
                    query: {
                        isHFDvue: 1,
                        interpolationDisabled: +interpolationDisabled,
                        selected_facility_metric: METRIC_KEY[measure],
                        timezone: timezone_1,
                    },
                });
            });
        }
    }, [sensor === null || sensor === void 0 ? void 0 : sensor.is_hfdvue, lastTimestamps, measure, personalSettingMeasure]);
    useEffect(function () {
        if (installationPoint.id) {
            var timezone_2 = getTimezone(installationPoint, selectedSystemType);
            fetchFftReadings(function () {
                var _a;
                return Endpoints[selectedSystemType].getFftReadings(installationPoint.id, {
                    query: {
                        highpass: ((_a = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.settings) === null || _a === void 0 ? void 0 : _a.high_pass) || 0,
                        interpolationDisabled: +interpolationDisabled,
                        selected_facility_metric: METRIC_KEY[measure],
                        timezone: timezone_2,
                    },
                });
            }, setStatesFftImpactVueChartsStore);
        }
    }, [installationPoint.id, measure, personalSettingMeasure]);
    var isModal = function (e) {
        var element = e === null || e === void 0 ? void 0 : e.target;
        return !!(element === null || element === void 0 ? void 0 : element.closest('.modal'));
    };
    var handler = function (val) {
        setStates({ isZoomEnabled: val });
    };
    var enableZoom = function (e) {
        if (e.repeat) {
            return;
        }
        if ((e.key !== CONTROL_KEY_NAME && e.key !== META_KEY_NAME) || isModal(e)) {
            if ((e.key === SHIFT_KEY_NAME || e.key === 'F5') && !isModal(e)) {
                handler(false);
            }
            return;
        }
        handler(true);
    };
    var disableZoom = function (e) {
        if ((e.key !== CONTROL_KEY_NAME && e.key !== META_KEY_NAME) || isModal(e)) {
            if ((e.ctrlKey || e.metaKey) && (e.key === SHIFT_KEY_NAME || e.key === 'F5') && !isModal(e)) {
                handler(true);
            }
            return;
        }
        handler(false);
    };
    useEffect(function () {
        // @ts-ignore
        document.addEventListener('click', setActiveChartOnClick);
        document.addEventListener('keydown', enableZoom);
        document.addEventListener('keyup', disableZoom);
        return function () {
            // @ts-ignore
            document.removeEventListener('click', setActiveChartOnClick);
            document.removeEventListener('keydown', enableZoom);
            document.removeEventListener('keyup', disableZoom);
        };
    }, []);
    var getReadingFftIds = function (chartIdentifiers, data, fftTimestampDates, fftSelectedDate, isImpactVueChart) {
        var newFftSelectedDate = moment(fftSelectedDate).format('YYYY-MM-DD HH:mm:ss');
        var readingFftIds = {};
        var newDates = {};
        var blockedFetch = false;
        chartIdentifiers.forEach(function (chartIdentifier) {
            var _a, _b;
            var dataGroup = chartIdentifier ? getDataGroup(chartIdentifier) : 1;
            var fftDates = _get(data, ['fftTimestamps', dataGroup]) || {};
            var chartGroup = CHART_DATA_GROUPS[dataGroup];
            if (isImpactVueChart) {
                chartGroup = chartGroup.filter(function (el) { return IMPACT_VUE_CHART_LIST.includes(el); });
            }
            else {
                chartGroup = chartGroup.filter(function (el) { return !IMPACT_VUE_CHART_LIST.includes(el); });
            }
            var formatDate = moment(newFftSelectedDate).format('YYYY-MM-DD'), availableDate = fftDates[formatDate], time = (_a = findClosestTimestamp(newFftSelectedDate, availableDate)) === null || _a === void 0 ? void 0 : _a.timestamp, readingFftId = (_b = findClosestTimestamp(newFftSelectedDate, availableDate)) === null || _b === void 0 ? void 0 : _b.readingFFT_id;
            if (readingFftId && chartIdentifier && +readingFftId !== 0) {
                readingFftIds[chartIdentifier] = +readingFftId;
            }
            console.log('readingFftIds', readingFftIds);
            chartGroup.map(function (chart) {
                newDates[chart] = time;
            });
            if (newFftSelectedDate === _get(fftTimestampDates, chartIdentifier) || !readingFftId || !time) {
                blockedFetch = true;
            }
        });
        if (!Object.values(readingFftIds).length || blockedFetch) {
            return {
                readingFftIds: null,
                newDates: null,
                newFftSelectedDate: null,
            };
        }
        return {
            readingFftIds: readingFftIds,
            newDates: newDates,
            newFftSelectedDate: newFftSelectedDate,
        };
    };
    useEffect(function () {
        if (!fftSelectedDate || _size(data) === 0) {
            return;
        }
        if (!prevActiveInstallationPointId) {
            return;
        }
        if (+prevActiveInstallationPointId !== +installationPoint.id) {
            return;
        }
        var _a = getReadingFftIds(isSupportZAxis(+sensor.version_type)
            ? [READING_TYPE_TWF_ACCELERATION_X, READING_TYPE_TWF_ACCELERATION_Y, READING_TYPE_TWF_ACCELERATION_Z]
            : [READING_TYPE_TWF_ACCELERATION_X, READING_TYPE_TWF_ACCELERATION_Y], data, fftTimestampDates, fftSelectedDate, false), readingFftIds = _a.readingFftIds, newDates = _a.newDates, newFftSelectedDate = _a.newFftSelectedDate;
        var allReadingFftIds = getReadingFftIds(isSupportZAxis(+sensor.version_type)
            ? Object.keys(DEFAULT_READING_CONFIG).map(Number)
            : [
                READING_TYPE_FFT_ACCELERATION_X,
                READING_TYPE_FFT_ACCELERATION_Y,
                READING_TYPE_FFT_VELOCITY_X,
                READING_TYPE_FFT_VELOCITY_Y,
                READING_TYPE_TWF_ACCELERATION_X,
                READING_TYPE_TWF_ACCELERATION_Y,
                READING_TYPE_TWF_VELOCITY_X,
                READING_TYPE_TWF_VELOCITY_Y,
            ], data, fftTimestampDates, fftSelectedDate, false).readingFftIds;
        if (!newDates || !readingFftIds || !newFftSelectedDate) {
            return;
        }
        setStatesFftChartsStore({
            fftTimestampDates: __assign(__assign({}, fftTimestampDates), newDates),
            isChangedCalendar: true,
            readingFftIds: allReadingFftIds,
            fftLastSelectedDate: newFftSelectedDate,
            fftSelectedDate: null,
            fftSelectedDateChartIdentifier: null,
        });
        fetchFftData(Object.values(readingFftIds), installationPoint, setStatesFftChartsStore, data, readings, selectedSystemType, measure, false, interpolationDisabled);
    }, [fftSelectedDate, data]);
    useEffect(function () {
        if (!fftSelectedDateImpactVue || _size(dataImpactVue) === 0) {
            return;
        }
        if (!prevActiveInstallationPointId) {
            return;
        }
        if (+prevActiveInstallationPointId !== +installationPoint.id) {
            return;
        }
        var _a = getReadingFftIds([fftImpactVueSelectedDateChartIdentifier], dataImpactVue, fftTimestampDatesImpactVue, fftSelectedDateImpactVue, true), readingFftIds = _a.readingFftIds, newDates = _a.newDates, newFftSelectedDate = _a.newFftSelectedDate;
        var allReadingFftIds = getReadingFftIds(IMPACT_VUE_FFT_TWF_CHART_LIST, dataImpactVue, fftTimestampDatesImpactVue, fftSelectedDateImpactVue, true).readingFftIds;
        if (!newDates || !readingFftIds || !newFftSelectedDate) {
            return;
        }
        setStatesFftImpactVueChartsStore({
            fftTimestampDates: __assign(__assign({}, fftTimestampDates), newDates),
            isChangedCalendar: true,
            readingFftIds: allReadingFftIds,
            fftLastSelectedDate: newFftSelectedDate,
            fftSelectedDate: null,
            fftSelectedDateChartIdentifier: null,
        });
        fetchFftData(Object.values(readingFftIds), installationPoint, setStatesFftImpactVueChartsStore, dataImpactVue, readings, selectedSystemType, measure, true, interpolationDisabled);
    }, [fftSelectedDateImpactVue, dataImpactVue]);
    useEffect(function () {
        if (_size(data) === 0) {
            return;
        }
        if (!prevActiveInstallationPointId) {
            return;
        }
        if (+prevActiveInstallationPointId !== +installationPoint.id) {
            return;
        }
        var newFftSelectedDate = moment(fftLastSelectedDate || moment()).format('YYYY-MM-DD HH:mm:ss');
        var chartIdentifiers = isSupportZAxis(+sensor.version_type)
            ? [READING_TYPE_TWF_ACCELERATION_X, READING_TYPE_TWF_ACCELERATION_Y, READING_TYPE_TWF_ACCELERATION_Z]
            : [READING_TYPE_TWF_ACCELERATION_X, READING_TYPE_TWF_ACCELERATION_Y];
        var readingFftIds = [];
        chartIdentifiers.forEach(function (chartIdentifier) {
            var _a;
            var dataGroup = getDataGroup(chartIdentifier);
            var fftDates = _get(data, ['fftTimestamps', dataGroup]) || {};
            var formatDate = moment(newFftSelectedDate).format('YYYY-MM-DD');
            var availableDate = fftDates[formatDate];
            if (!availableDate && !fftLastSelectedDate) {
                availableDate = Object.values(fftDates)[Object.values(fftDates).length - 1];
            }
            var readingFftId = (_a = findClosestTimestamp(newFftSelectedDate, availableDate)) === null || _a === void 0 ? void 0 : _a.readingFFT_id;
            if (readingFftId) {
                readingFftIds.push(+readingFftId);
            }
        });
        fetchFftData(readingFftIds, installationPoint, setStatesFftChartsStore, data, readings, selectedSystemType, measure, false, interpolationDisabled);
    }, [(_a = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.settings) === null || _a === void 0 ? void 0 : _a.high_pass, interpolationDisabled]);
    var chartSorting = selectChartSorting();
    var isShowImpactVueCharts = function (chartIdentifier) {
        return IMPACT_VUE_CHART_LIST.includes(chartIdentifier) ? isEnabledImpactVue && isSupportHfdvue : true;
    };
    useChartHotkey();
    return (_jsxs(React.Fragment, { children: [!isAvailableEquipments ? (_jsx(React.Fragment, {})) : (_jsx("div", { className: "charts-wrapper ".concat(chartView), id: "charts-wrapper", children: isSortablePoint(installationPoint) ? (chartSorting.map(function (_a) {
                    var chartIdentifier = _a.chartIdentifier, isVisible = _a.isVisible;
                    return (_jsx(React.Fragment, { children: (!isSupportZAxis(+sensor.version_type) &&
                            +_get(readingTypes, [chartIdentifier, 'axisId'], 1) === AXIS_Z) ||
                            !isShowImpactVueCharts(chartIdentifier) ||
                            !isVisible ? (_jsx(_Fragment, {})) : (_jsx(ChartComponent, { chartIdentifier: +chartIdentifier })) }, "chart-identifier-".concat(chartIdentifier)));
                })) : (_jsx(_Fragment, { children: Object.keys(readingTypes).map(function (chartIdentifier) { return (_jsx(React.Fragment, { children: _jsx(ChartComponent, { chartIdentifier: +chartIdentifier }) }, "chart-identifier-".concat(chartIdentifier))); }) })) })), changeAlertConditions.isVisibleModal && _jsx(ChangeAlarmLevelsModal, {}), removePoint.isVisibleModal ? _jsx(RemovePointModal, {}) : _jsx(_Fragment, {}), closestFft.isVisibleModal ? _jsx(ClosestFft, {}) : _jsx(_Fragment, {}), isVisibleManageBearings ? _jsx(ManageBearingsModal, {}) : _jsx(_Fragment, {}), customBearingModal.visible ? _jsx(CustomBearingModal, {}) : _jsx(_Fragment, {}), isVisibleManageFaultFrequencies ? (_jsx(ManageFaultFrequenciesModal, { installationPoint: installationPoint })) : (_jsx(_Fragment, {})), isVisibleIdleThresholdModal ? _jsx(SetIdleThresholdModal, {}) : _jsx(_Fragment, {}), setRunningSpeed.visible ? _jsx(SetRunningSpeedModal, {}) : _jsx(_Fragment, {}), equipmentSpeedVisible ? _jsx(EquipmentSpeedModal, {}) : _jsx(_Fragment, {}), setFaultFrequency.visible ? _jsx(SetFaultFrequencyModal, {}) : _jsx(_Fragment, {}), bandAlarmsFormModal.visible ? _jsx(BandsFormModal, {}) : _jsx(_Fragment, {}), envelopeAlarmsFormModal.visible ? _jsx(EnvelopeFormModal, {}) : _jsx(_Fragment, {}), waterfallPlotModal.visible ? _jsx(WaterfallPlotModal, {}) : _jsx(_Fragment, {}), stateSuggestedLevelFormulaModal.visible ? (_jsx(SuggestedLevelFormulaModal, { stateSuggestedLevelFormulaModal: stateSuggestedLevelFormulaModal, callback: function () { } })) : (_jsx(_Fragment, {})), hidePeaksModal.visible ? (_jsx(HidePeaksModal, { visible: hidePeaksModal.visible, state: hidePeaksModal.state, dispatch: hidePeaksModal.dispatch, chartIdentifier: hidePeaksModal.chartIdentifier })) : (_jsx(_Fragment, {})), _jsx(PaintingLeftBar, {})] }));
};
var ChartComponent = function (_a) {
    var chartIdentifier = _a.chartIdentifier;
    if (isFftTwfChartImpactVue(chartIdentifier)) {
        return _jsx(FftTwfChartImpactVue, { chartIdentifier: chartIdentifier });
    }
    if (isFftTwfChart(chartIdentifier)) {
        return _jsx(FftTwfChart, { chartIdentifier: chartIdentifier });
    }
    return _jsx(RmsChart, { chartIdentifier: chartIdentifier });
};
export default memo(ChartsWrapper);
