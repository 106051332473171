import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo, useContext, useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import { SYSTEM_TYPE_AMAZON, SYSTEM_TYPE_REMASTERED } from '../../../constants';
import { useNameplatesQuery, } from '../../../remastered/services/nameplates/useNameplatesQuery';
import BearingsTable from '../../components/bearings/BearingsTable';
import BearingsTitle from '../../components/bearings/BearingsTitle';
import FaultFrequenciesTable from '../../components/faultFrequency/FaultFrequenciesTable';
import DataTable from '../../components/shared/Table/Table';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { InstallationPointStore } from '../../store/charts/chartsContent/InstallationPointStore';
import './Nameplates.scss';
import NameplatesContent from './components/NameplatesContent';
var Nameplates = function (_a) {
    var _b;
    var installationPoint = _a.installationPoint, _c = _a.readonly, readonly = _c === void 0 ? false : _c;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var equipment = _get(installationPoint, 'equipment', {});
    var _d = useState(installationPoint.id), selectedInstallationPointId = _d[0], setSelectedInstallationPointId = _d[1];
    var _e = useState(false), isVisibleFaultFrequencyBlock = _e[0], setIsVisibleFaultFrequencyBlock = _e[1];
    var _f = useState(false), isVisibleBearingsBlock = _f[0], setIsVisibleBearingsBlock = _f[1];
    var _g = useState(false), smallLoader = _g[0], setSmallLoader = _g[1];
    var pointsOnEquipment = (_b = equipment === null || equipment === void 0 ? void 0 : equipment.installationPoints) !== null && _b !== void 0 ? _b : [];
    var getPointName = function (installationPoint) {
        var _a;
        return (_a = installationPoint.name) !== null && _a !== void 0 ? _a : installationPoint.component + installationPoint.point_name;
    };
    return (_jsxs(_Fragment, { children: [_jsx(Row, { className: "mt-2", children: _jsx(Col, { md: 4, children: readonly ? (_jsx("h4", { className: "pb-3", children: getPointName(installationPoint) })) : (_jsx(Select, { classNamePrefix: "customSelectStyleDefault", placeholder: "Installation points", defaultValue: { label: getPointName(installationPoint), value: installationPoint.id }, options: pointsOnEquipment.map(function (point) { return ({
                            label: getPointName(point),
                            value: point.id,
                        }); }), onChange: function (item) {
                            return setSelectedInstallationPointId(+item.value);
                        } })) }) }), _jsx(NameplatesBlock, { selectedInstallationPointId: selectedInstallationPointId, equipment: equipment, readonly: readonly }), _jsx(SpeedSettingsBlock, { selectedInstallationPointId: selectedInstallationPointId }), _jsx(Row, { children: _jsxs(Col, { md: 12, className: "mt-2", children: [_jsxs("h6", { className: "float-start", children: [_jsx("b", { className: "me-2 cursor-pointer", onClick: function () { return setIsVisibleFaultFrequencyBlock(!isVisibleFaultFrequencyBlock); }, children: isVisibleFaultFrequencyBlock ? _jsx(FaMinus, {}) : _jsx(FaPlus, {}) }), _jsx("span", { children: "Fault/Forcing Frequencies" })] }), isVisibleFaultFrequencyBlock && (_jsx(FaultFrequenciesTable, { installationPoint: installationPoint, readonly: readonly }))] }) }), selectedSystemType !== SYSTEM_TYPE_AMAZON && (_jsx(Row, { children: _jsxs(Col, { md: 12, children: [_jsxs("div", { className: "mb-2", children: [_jsx("b", { className: "me-2 cursor-pointer", onClick: function () { return setIsVisibleBearingsBlock(!isVisibleBearingsBlock); }, children: isVisibleBearingsBlock ? _jsx(FaMinus, {}) : _jsx(FaPlus, {}) }), _jsx(BearingsTitle, { smallLoader: smallLoader, selectedInstallationPointId: selectedInstallationPointId, readonly: readonly })] }), isVisibleBearingsBlock && (_jsx(BearingsTable, { setSmallLoader: setSmallLoader, selectedInstallationPointId: selectedInstallationPointId, readonly: readonly }))] }) }))] }));
};
var SpeedSettingsBlock = function (_a) {
    var selectedInstallationPointId = _a.selectedInstallationPointId;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var currentSpeed = InstallationPointStore(function (state) { return state.currentSpeed; });
    if (selectedSystemType !== SYSTEM_TYPE_REMASTERED || currentSpeed === null) {
        return _jsx(_Fragment, {});
    }
    var nameplatesData = useNameplatesQuery({
        installationPointId: selectedInstallationPointId,
    }).data;
    return (_jsx(Row, { className: "mt-2 mb-2", children: _jsx(Col, { md: 12, children: _jsx(DataTable, { config: {
                    columns: [
                        {
                            name: 'installation_point_speed',
                            title: 'Inst. Point Speed',
                        },
                        {
                            name: 'installation_point_speed_ratio',
                            title: 'Inst. Point Speed Ratio',
                        },
                        {
                            name: 'equipment_speed_type',
                            title: 'Equipment Speed Type',
                        },
                        {
                            name: 'equipment_speed',
                            title: 'Equipment Speed',
                        },
                        {
                            name: 'equipment_min_speed',
                            title: 'Equipment Min Speed',
                        },
                        {
                            name: 'equipment_max_speed',
                            title: 'Equipment Max Speed',
                        },
                    ],
                    pagination: {
                        disabled: true,
                    },
                    disabledSearch: true,
                    data: (nameplatesData === null || nameplatesData === void 0 ? void 0 : nameplatesData.data) || [],
                    loader: false,
                    hideTotalLabel: true,
                } }) }) }));
};
var NameplatesBlock = function (_a) {
    var selectedInstallationPointId = _a.selectedInstallationPointId, equipment = _a.equipment, readonly = _a.readonly;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    if (selectedSystemType !== SYSTEM_TYPE_REMASTERED) {
        return _jsx(_Fragment, {});
    }
    var nameplatesData = useNameplatesQuery({
        installationPointId: selectedInstallationPointId,
    }).data;
    return (_jsxs("div", { className: "mt-2", children: [_jsx(NameplatesContent, { logs: (nameplatesData === null || nameplatesData === void 0 ? void 0 : nameplatesData.equipmentNameplateLogs) || [], noPhotoReason: nameplatesData === null || nameplatesData === void 0 ? void 0 : nameplatesData.equipmentNoPhotoReason, coreLink: "all-analytics/equipment", id: equipment.id, selectedInstallationPointId: selectedInstallationPointId, data: (nameplatesData === null || nameplatesData === void 0 ? void 0 : nameplatesData.equipmentNameplate) || [], name: "Equipment nameplate", readonly: readonly }), _jsx(NameplatesContent, { logs: (nameplatesData === null || nameplatesData === void 0 ? void 0 : nameplatesData.installationPointNameplateLog) || [], coreLink: "all-analytics/installation-point", id: selectedInstallationPointId, selectedInstallationPointId: selectedInstallationPointId, data: (nameplatesData === null || nameplatesData === void 0 ? void 0 : nameplatesData.installationPointNameplate) || [], name: "Installation point nameplate", readonly: readonly })] }));
};
export default memo(Nameplates);
