import { jsx as _jsx } from "react/jsx-runtime";
import { useQueryClient as useUntypedQueryClient } from '@tanstack/react-query';
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { severityIncreaseQueryKey } from '../../services/actionItems/useSeverityIncreaseQuery';
import { useConfirmModalActions } from '../../store/global/useConfirmModalStore';
var useQueryClient = useUntypedQueryClient;
export var useDeleteSeverityIncrease = function (_a) {
    var actionItemId = _a.actionItemId, _b = _a.isPreAi, isPreAi = _b === void 0 ? false : _b, _c = _a.confirmationText, confirmationText = _c === void 0 ? 'Are you sure you want to delete this item?' : _c, approveSeverityIncreaseMutate = _a.approveSeverityIncreaseMutate, declineSeverityIncreaseMutate = _a.declineSeverityIncreaseMutate, onSuccess = _a.onSuccess;
    if (!actionItemId) {
        throw new Error('`actionListId` should be number');
    }
    var queryClint = useQueryClient();
    var refetchSeverityIncrease = function () {
        queryClint.invalidateQueries({
            queryKey: severityIncreaseQueryKey({ actionItemId: actionItemId }),
        });
    };
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var deleteSeverityIncrease = function (action, onConfirmSuccess) {
        var mutate = action === 'approve' ? approveSeverityIncreaseMutate : declineSeverityIncreaseMutate;
        setConfirmData({
            isVisible: false,
        });
        mutate(isPreAi ? { pre_action_list_id: actionItemId } : { action_list_id: actionItemId }, {
            onSuccess: function () {
                toast.success('Severity Increase successfully removed', {
                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                });
                refetchSeverityIncrease();
                onConfirmSuccess === null || onConfirmSuccess === void 0 ? void 0 : onConfirmSuccess();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
            },
        });
    };
    var confirmDeleteSeverityIncrease = function (_a) {
        var _b = _a === void 0 ? {} : _a, onSuccess = _b.onSuccess;
        setConfirmData({
            isVisible: true,
            textConfirmationModal: confirmationText,
            confirmText: 'Accept',
            additionalBtns: [
                {
                    text: 'Reject',
                    outline: false,
                    color: 'danger',
                    onAction: function () { return deleteSeverityIncrease('decline', onSuccess); },
                },
            ],
            onConfirm: function () { return deleteSeverityIncrease('approve', onSuccess); },
        });
    };
    return { confirmDeleteSeverityIncrease: confirmDeleteSeverityIncrease };
};
